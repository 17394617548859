import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "line", "button"]
  static values = { id: Number }

  initialize() {
    if (typeof lineIds == "undefined" ) {
      window.lineIds = []
      console.log(lineIds)
    }
  }

  click(){
    
    console.log("Click Value: " + this.idValue)
    lineIds.push(this.idValue)
    let line = this.lineTarget
    let button = this.buttonTarget
    var selectedMessage = document.getElementById("selectedMessage")
    selectedMessage.innerText = lineIds.length + " Items selected "
    button.style = "display:none;"
    line.classList.add("active")

    console.log(lineIds)
    document.getElementById("lineitem_ids").value = lineIds
    document.getElementById("submit").style = "display:block;"

  }

  disconnect(){
    window.lineIds = []
  }

}
