import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select_box" ]

  connect() {
  }


  change() {
    let box = this.select_boxTarget
    let id = box.value
    let url = "/jobtypes/" + id + "/info"
    fetch(url)
    .then(res => res.json())
    .then((data) => {
      this.changed(data)
    }).catch(err => console.error(err));

  }

  changed(data){
    let additiona_services_input = document.getElementById("quote_additional_services")
    additiona_services_input.value = ""
    additiona_services_input.value = data.proposal_additional_services

    let scope_of_work_input = document.getElementById("quote_scope_of_work")
    scope_of_work_input.value = ""
    scope_of_work_input.value = data.proposal_scope_of_work
  }

}
