import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    console.log("customer_select")
    
    $( "#customer_id" ).change(function() {
      alert( "Handler for .change() called." );
    });

  }
}