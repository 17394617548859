import { Controller } from "stimulus"
import selectize from "selectize"

export default class extends Controller {
  static targets = ["select_box"]

  connect() {
    var that = this
    this.initSelect(that);
    console.log("select");
  }

  initSelect(that){
    var rel = this.data.get("rel");

    const customer_options = {
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      options: [],
      onChange: function(value) {
        that.changed(value, that)
      },
      create: false,
      load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: '/customers/search',
          type: 'GET',
          dataType: 'json',
          data: {
            name: query,
          }

        });
      }
    }

    const other_options = {
      create: false
    }

    if (rel == "customers") {
      const options = customer_options
    } else {
      const options = other_options
    }


    $(that.select_boxTarget).selectize(customer_options);

  }

  changed(value, that) {
    const rel = this.data.get("rel");

    if (rel == "customers") {
      let form = this.data.get("form");
      console.log("changed to " + value + " " + rel + form)
      const parentRef = "&customerId=" + value
      var siteSelect = $('#siteSelect')
      var contactSelect = $('#contactSelect')

      $.ajax({
        dataType: "json",
        url: "/sites/dropdown_select/" + form + "/" + value,
        success: function(res) {
          siteSelect.replaceWith(res.select)
        },
        eror: function (res) {
          console.log("error")
        }
      });
      
      $.ajax({
        dataType: "json",
        url: "/contacts/dropdown_select/" + form + "/" + value,
        success: function(res) {
          contactSelect.replaceWith(res.select)
        },
        eror: function (res) {
          console.log("error")
        }
      });
    }
      


  }

  disconnect() {
    $(this.select_boxTarget)[0].selectize.destroy();
  }

}
