import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element["billQ"] = this
    console.log("ID: " + this.element["billQ"].element)
    // this.outputTarget.textContent = 'Hello, Stimulus!'
  }

  click() {
    alert("123")
  }

}
