// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo


import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.css'
const images = require.context('../images', true)

import '../stylesheets/application.scss';

import "bootstrap";
import "@coreui/coreui-pro";


import '../javascripts/index.js';

import "controllers"

require("trix")
require("@rails/actiontext")
