// Shared JS

$( document ).on('turbo:load', function() {
  dtables();
})


function dtables() {
  $(".dataTable").DataTable({
    responsive: false,
    iDisplayLength: 25,
    aaSorting: [[0, 'desc']],
    bDestroy: true,
    bLengthChange: false
  });
}
