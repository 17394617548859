import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect(){
    var type  = this.data.get("type")
    const field = this.fieldTarget
    this.basic(field)
  }

  basic(field){
    $(field).filter(":visible").selectize({});
  }


}
